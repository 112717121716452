import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { AbstractQueueService } from '@dougs/core/queue';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { getVendorInvoicePaymentStatus } from '../../utils/vendor-invoice.utils';

@Injectable()
export class VendorInvoiceQueueService extends AbstractQueueService<VendorInvoice, null> {
  constructor(
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly metricsService: MetricsService,
  ) {
    super();
  }

  buildCall: (updatedVendorInvoice: VendorInvoice) => () => Observable<VendorInvoice | null> = (
    updatedVendorInvoice: VendorInvoice,
  ) => {
    return (): Observable<VendorInvoice | null> =>
      from(this.vendorInvoiceStateService.updateVendorInvoiceWithoutUpdateState(updatedVendorInvoice));
  };

  resetCall: () => Observable<VendorInvoice | null> = (): Observable<VendorInvoice | null> => {
    return from(this.vendorInvoiceStateService.refreshVendorInvoiceById(this.model.companyId, this.model.id));
  };

  updateModelInState: (model: VendorInvoice) => void = (model: VendorInvoice): void => {
    this.vendorInvoiceStateService.updateVendorInvoiceStateFromPaymentStatus(model);
  };

  postCallTreatmentFn: (callResult: VendorInvoice | null) => void = (callResult: VendorInvoice | null) => {
    if (callResult) {
      if (callResult.paymentStatus !== this.model.paymentStatus) {
        this.metricsService.pushMixpanelEvent('Supplier Invoice Status Updated', {
          'Previous Status': getVendorInvoicePaymentStatus(this.model),
          'New Status': getVendorInvoicePaymentStatus(callResult),
        });
      }

      if (this.model.amount !== callResult.amount) {
        this.metricsService.pushMixpanelEvent('Supplier Invoice Amount Updated', {
          Amount: callResult.amount,
        });
      }
    }
  };
}
