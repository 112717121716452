import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, Subscription, tap, timer } from 'rxjs';

@Directive({
  selector: '[dougsAnimationTimeout]',
  exportAs: 'animationTimeoutDirective',
  standalone: true,
})
export class AnimationTimeoutDirective implements OnDestroy {
  private readonly disableAnimationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly disableAnimation$: Observable<boolean> = this.disableAnimationSubject.asObservable();

  @Output() animationEnded: EventEmitter<void> = new EventEmitter<void>();

  private subscription?: Subscription;

  private timeLeft = 10;

  startTimer(): void {
    const timerSource: Observable<void> = timer(0, 1000).pipe(
      filter((val): val is number => !!val),
      tap(() => this.timeLeft--),
      filter(() => this.timeLeft <= 0),
      map(() => this.disableAnimationSubject.next(true)),
      map(() => this.animationEnded.emit()),
    );
    this.subscription = timerSource.subscribe();
  }

  stopTimer(): void {
    this.timeLeft = 10;
    this.subscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
