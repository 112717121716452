<form [formGroup]="vendorInvoiceModalComponentService.formGroup">
  <div class="header mx-24 mt-24">
    <div class="header__title">
      <h6>{{ vendorInvoice.label }}</h6>
      <dougs-memo
        (focus)="vendorInvoiceModalComponentService.onInputFocus('memo')"
        (blur)="vendorInvoiceModalComponentService.onInputBlur('memo')"
        formControlName="memo"
        [light]="true"
      ></dougs-memo>
    </div>
    <dougs-icon-button
      size="medium"
      iconClass="ellipsis-v"
      type="regular"
      color="ghost"
      [dougsDropdownTrigger]="dropdownActions"
    />
  </div>
</form>
<dougs-dropdown #dropdownActions [displayArrow]="false" [widthToRefElement]="false">
  <dougs-dropdown-option [closeOnClick]="true">
    <a [href]="url" target="_blank">
      <i class="fal fa-file-download mr-8"></i>
      Télécharger
    </a>
  </dougs-dropdown-option>
  <dougs-dropdown-option
    (click)="vendorInvoiceActionsComponentService.renameVendorInvoice(vendorInvoice)"
    [closeOnClick]="true"
  >
    <i class="fal fa-pen mr-8"></i>
    Renommer
  </dougs-dropdown-option>
  <dougs-dropdown-option
    (click)="vendorInvoiceModalComponentService.deleteVendorInvoice(vendorInvoice)"
    [closeOnClick]="true"
  >
    <span class="color-error">
      <i class="fal fa-trash mr-8"></i>
      Supprimer
    </span>
  </dougs-dropdown-option>
</dougs-dropdown>
