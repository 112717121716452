import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { VendorInvoiceModalComponentService } from '../../services/modals/vendor-invoice-modal.component.service';
import { VendorInvoiceOperationsComponentService } from '../../services/operation/vendor-invoice-operations.component.service';
import { VendorInvoiceOperationListSearchComponent } from '../vendor-invoice-operation-list/vendor-invoice-operation-list-search/vendor-invoice-operation-list-search.component';
import { VendorInvoiceOperationListComponent } from '../vendor-invoice-operation-list/vendor-invoice-operation-list.component';

@Component({
  selector: 'dougs-vendor-invoice-attach-operation',
  standalone: true,
  imports: [
    CommonModule,
    VendorInvoiceOperationListComponent,
    VendorInvoiceOperationListSearchComponent,
    InfiniteScrollModule,
  ],
  providers: [
    VendorInvoiceOperationsComponentService,
    {
      provide: OPERATION_STATE_TOKEN,
      useExisting: ModalOperationsStateService,
    },
  ],
  templateUrl: './vendor-invoice-attach-operation.component.html',
  styleUrls: ['vendor-invoice-attach-operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorInvoiceAttachOperationComponent {
  constructor(
    public readonly vendorInvoiceModalComponentService: VendorInvoiceModalComponentService,
    public vendorInvoiceOperationsComponentService: VendorInvoiceOperationsComponentService,
  ) {}
}
