import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  DividerComponent,
  DRAWER_DATA,
  DrawerCloseDirective,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  InvoicePreview,
  LabelFormFieldDirective,
  LoaderComponent,
  MemoComponent,
  ModalContentDirective,
  ModalFooterDirective,
  SelectComponent,
  SelectOptionComponent,
  ShowOperationDividerPipe,
  SuffixFormFieldDirective,
  TrackByPipe,
} from '@dougs/ds';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OperationCompactDirective } from '@dougs/operations/ui';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceAttachOperationComponent } from '../../components/vendor-invoice-attach-operation/vendor-invoice-attach-operation.component';
import { VendorInvoiceDetailsComponent } from '../../components/vendor-invoice-details/vendor-invoice-details.component';
import { VendorInvoiceOperationComponent } from '../../components/vendor-invoice-operation-list/vendor-invoice-operation/vendor-invoice-operation.component';
import { VendorInvoiceOperationListSearchComponent } from '../../components/vendor-invoice-operation-list/vendor-invoice-operation-list-search/vendor-invoice-operation-list-search.component';
import { VendorInvoiceOperationListComponent } from '../../components/vendor-invoice-operation-list/vendor-invoice-operation-list.component';
import { VendorInvoiceActionsComponentService } from '../../services/details/vendor-invoice-actions.component.service';
import { VendorInvoiceQueueService } from '../../services/details/vendor-invoice-queue.service';
import { VendorInvoiceModalComponentService } from '../../services/modals/vendor-invoice-modal.component.service';
import { VendorInvoiceOperationSearchComponentService } from '../../services/operation/vendor-invoice-operation-search.component.service';

@Component({
  selector: 'dougs-vendor-invoice-modal',
  standalone: true,
  imports: [
    CommonModule,
    DrawerCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    MemoComponent,
    DividerComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    SuffixFormFieldDirective,
    SelectComponent,
    SelectOptionComponent,
    ReactiveFormsModule,
    VendorInvoiceOperationListComponent,
    TrackByPipe,
    VendorInvoiceOperationComponent,
    LoaderComponent,
    DropdownComponent,
    DropdownOptionComponent,
    DropdownTriggerDirective,
    VendorInvoiceOperationListSearchComponent,
    OperationCompactDirective,
    ShowOperationDividerPipe,
    VendorInvoiceDetailsComponent,
    VendorInvoiceAttachOperationComponent,
    InvoicePreview,
  ],
  templateUrl: './vendor-invoice-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    VendorInvoiceModalComponentService,
    VendorInvoiceQueueService,
    VendorInvoiceOperationSearchComponentService,
    VendorInvoiceActionsComponentService,
  ],
  animations: [
    trigger('vendorInvoiceForm', [
      transition(':enter', [
        style({ position: 'absolute', width: '100%', transform: 'translateX(-110%)' }),
        animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ position: 'absolute', width: '100%', transform: 'translateX(0)' }),
        animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(-110%)' })),
      ]),
    ]),
    trigger('attachOperation', [
      transition(':enter', [
        style({ transform: 'translateX(110%)' }),
        animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(110%)' })),
      ]),
    ]),
  ],
})
export class VendorInvoiceModalComponent implements OnInit {
  constructor(
    @Inject(DRAWER_DATA) public readonly vendorInvoice: VendorInvoice,
    public readonly vendorInvoiceModalComponentService: VendorInvoiceModalComponentService,
    public readonly companyServicesStateService: CompanyServicesStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.vendorInvoice) {
      this.vendorInvoiceModalComponentService.updateVendorInvoice(this.vendorInvoice);
    }
    await this.vendorInvoiceModalComponentService.refreshVendorInvoiceOperations(this.vendorInvoice);
  }
}
