import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  DividerComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  PillComponent,
  SearchBarComponent,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { VendorInvoiceModalComponentService } from '../../../services/modals/vendor-invoice-modal.component.service';
import { VendorInvoiceOperationSearchComponentService } from '../../../services/operation/vendor-invoice-operation-search.component.service';
import { VendorInvoiceOperationsComponentService } from '../../../services/operation/vendor-invoice-operations.component.service';

@Component({
  selector: 'dougs-vendor-invoice-operation-list-search',
  standalone: true,
  imports: [
    CommonModule,
    SearchBarComponent,
    ReactiveFormsModule,
    PillComponent,
    FormFieldComponent,
    ControlFormFieldDirective,
    SuffixFormFieldDirective,
    LabelFormFieldDirective,
    ButtonComponent,
    DividerComponent,
  ],
  templateUrl: './vendor-invoice-operation-list-search.component.html',
  styleUrls: ['./vendor-invoice-operation-list-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorInvoiceOperationListSearchComponent {
  constructor(
    public readonly vendorInvoiceOperationSearchComponentService: VendorInvoiceOperationSearchComponentService,
    public readonly vendorInvoiceModalComponentService: VendorInvoiceModalComponentService,
    public readonly vendorInvoiceOperationsComponentService: VendorInvoiceOperationsComponentService,
  ) {}
}
