<dougs-operation
  [changeUrlOnClick]="false"
  [isInOperationListModal]="true"
  [isCompact]="isCompact"
  [canToggleOperationDetails]="!vendorInvoiceOperationComponentService.isEcommerceOperation"
  [operation]="vendorInvoiceOperationComponentService.operation"
  [synchronizedAccounts]="synchronizedAccountStateService.synchronizedAccounts$ | async"
  [disableReceiptMatching]="true"
  [ngClass]="{ 'is-attached': vendorInvoiceOperationComponentService.isOperationAttached$() && !isInForm }"
  (detachVendorInvoiceSourceDocument)="detachVendorInvoice($event)"
>
  <ng-template>
    <dougs-vendor-invoice-operation-actions
      [operation]="vendorInvoiceOperationComponentService.operation"
      [isCompact]="isCompact"
      [disableAttachActions]="disableAttachActions"
      [showDefaultOperationActions]="showDefaultOperationActions"
      (detachOperationEmitter)="detachOperation($event)"
      (attachOperationEmitter)="attachOperation($event)"
    ></dougs-vendor-invoice-operation-actions>
  </ng-template>
</dougs-operation>
