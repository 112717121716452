import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, concatMap, distinctUntilChanged, Observable, tap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalOperationsStateService } from '@dougs/operations/shared';

@Injectable()
export class VendorInvoiceOperationSearchComponentService {
  private readonly hasCurrentSearchSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasCurrentSearch$: Observable<boolean> = this.hasCurrentSearchSubject.asObservable();

  private readonly isSearching: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSearching$: Observable<boolean> = this.isSearching.asObservable();

  searchFormGroup: FormGroup = new FormGroup({
    search: new UntypedFormControl(''),
    amount: new UntypedFormControl(''),
    date: new UntypedFormControl(''),
    isInbound: new UntypedFormControl(false),
    isNotInbound: new UntypedFormControl(false),
  });

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly modalOperationsStateService: ModalOperationsStateService,
  ) {}

  searchOperations$: Observable<void> = combineLatest([
    this.companyStateService.activeCompany$,
    this.searchFormGroup.valueChanges,
  ]).pipe(
    debounceTime(500),
    distinctUntilChanged(),
    tap(() => this.isSearching.next(true)),
    concatMap(([activeCompany, values]) =>
      this.modalOperationsStateService.refreshOperations(activeCompany.id, {
        ...values,
        autogenerated: false,
        excludeAccrualOperations: true,
      }),
    ),
    tap(() => this.isSearching.next(false)),
    tap(() => this.hasCurrentSearchSubject.next(this.hasCurrentSearch())),
  );

  onClearSearch(): void {
    this.searchFormGroup.reset({});
    this.hasCurrentSearchSubject.next(false);
  }

  searchBoolean(formControlName: string): void {
    this.searchFormGroup.get(formControlName)?.setValue(!this.searchFormGroup.get(formControlName)?.value);
  }

  hasFiltersApplied(): boolean {
    return !!(
      this.searchFormGroup.get('amount')?.value ||
      this.searchFormGroup.get('date')?.value ||
      this.searchFormGroup.get('isInbound')?.value ||
      this.searchFormGroup.get('isNotInbound')?.value
    );
  }

  private hasCurrentSearch(): boolean {
    return !!this.searchFormGroup.get('search')?.value || this.hasFiltersApplied();
  }
}
