<ng-container *ngIf="vendorInvoiceOperationsComponentService.refreshOperations$ | async"></ng-container>
<ng-container *ngIf="vendorInvoiceOperationsComponentService.loadMoreOperations$ | async"></ng-container>

<div class="operation-list-container p-16">
  <dougs-loader
    *ngIf="
      (vendorInvoiceOperationsComponentService.isLoading$ | async) ||
      (vendorInvoiceOperationSearchComponentService.isSearching$ | async)
    "
  ></dougs-loader>
  <ng-container
    *ngIf="
      !(vendorInvoiceOperationsComponentService.isLoading$ | async) &&
      !(vendorInvoiceOperationSearchComponentService.isSearching$ | async)
    "
  >
    <ng-container *ngIf="(modalOperationsStateService.operations$ | async)?.length; else blankState">
      <div class="operation-list" dougsOperationCompact #compactDirective="compactDirective">
        <ng-container
          *ngFor="
            let operation of modalOperationsStateService.operations$ | async as operations;
            trackBy: 'id' | trackBy;
            let isFirst = first;
            let index = index
          "
        >
          <div
            *ngIf="operations | showOperationDivider: index : isFirst"
            class="px-16 py-8 operation-list__date"
            [ngClass]="{
              'operation-list__date__filter-block': vendorInvoiceOperationsComponentService.filterBlockOpened$ | async
            }"
          >
            {{ operation.date | date: 'MMMM yyyy' | titlecase }}
          </div>
          <dougs-vendor-invoice-operation
            [operation]="operation"
            [vendorInvoice]="vendorInvoice"
            [isCompact]="compactDirective.isCompact"
          ></dougs-vendor-invoice-operation>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #blankState>
      <div *ngIf="!(vendorInvoiceOperationsComponentService.isLoading$ | async)" class="mx-32">
        <dougs-blank-state>
          <p class="small mt-16">Ho ho... Aucune opération pour votre recherche</p>
        </dougs-blank-state>
      </div>
    </ng-template>
  </ng-container>
</div>
