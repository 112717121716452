<ng-container *ngIf="vendorInvoiceModalComponentService.updateOperationFromSocket$ | async"></ng-container>
<ng-container *ngIf="vendorInvoiceModalComponentService.populateFormGroup$ | async"></ng-container>
<div class="invoice-drawer">
  <dougs-invoice-preview
    [invoiceFile]="vendorInvoiceModalComponentService.vendorInvoiceFile$ | async"
  ></dougs-invoice-preview>
  <div class="invoice-drawer__right">
    <div class="invoice-drawer__right__wrapper">
      <dougs-vendor-invoice-details
        *ngIf="!vendorInvoiceModalComponentService.isAttachingOperations"
        (attachOperation)="
          vendorInvoiceModalComponentService.isAttachingOperations =
            !vendorInvoiceModalComponentService.isAttachingOperations
        "
        (createVendorInvoiceOperation)="vendorInvoiceModalComponentService.createVendorInvoiceOperation()"
        (createExpenseOperation)="vendorInvoiceModalComponentService.createVendorInvoiceOperation('expense')"
        @vendorInvoiceForm
        class="invoice-drawer__right__wrapper__content"
      ></dougs-vendor-invoice-details>
      <dougs-vendor-invoice-attach-operation
        *ngIf="vendorInvoiceModalComponentService.isAttachingOperations"
        @vendorInvoiceForm
        class="invoice-drawer__right__wrapper__content"
      ></dougs-vendor-invoice-attach-operation>
    </div>
    <div
      *ngIf="!vendorInvoiceModalComponentService.isAttachingOperations"
      class="invoice-drawer__right__footer py-16 px-24"
    >
      <dougs-button (click)="vendorInvoiceModalComponentService.finishCurrentStep()" color="secondary">
        Terminer
      </dougs-button>
    </div>
  </div>
</div>
