import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { SourceDocumentAttachment } from '@dougs/core/files';
import { ButtonComponent, TooltipDirective } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OperationComponent } from '@dougs/operations/ui';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceAttachButtonColorPipe } from '../../../pipes/vendor-invoice-attach-button-color.pipe';
import { VendorInvoiceOperationComponentService } from '../../../services/operation/vendor-invoice-operation.component.service';
import { VendorInvoiceOperationActionsComponent } from '../vendor-invoice-operation-actions/vendor-invoice-operation-actions.component';

@Component({
  selector: 'dougs-vendor-invoice-operation',
  standalone: true,
  imports: [
    CommonModule,
    OperationComponent,
    ButtonComponent,
    VendorInvoiceAttachButtonColorPipe,
    TooltipDirective,
    VendorInvoiceOperationActionsComponent,
  ],
  templateUrl: './vendor-invoice-operation.component.html',
  styleUrls: ['./vendor-invoice-operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VendorInvoiceOperationComponentService],
})
export class VendorInvoiceOperationComponent {
  @Input() isInForm = false;
  @Input() showDefaultOperationActions = false;
  @Input() isCompact = false;
  @Input() disableAttachActions = false;

  @Input()
  @HostBinding('class.last-child')
  isLast = false;

  private _operation!: Operation;

  get operation(): Operation {
    return this._operation;
  }

  @Input()
  set operation(operation: Operation) {
    this._operation = operation;
    this.vendorInvoiceOperationComponentService.setOperation(operation);
    this.vendorInvoiceOperationComponentService.operationIsAttached(this.operation, this.vendorInvoice);
  }

  private _vendorInvoice!: VendorInvoice;

  get vendorInvoice(): VendorInvoice {
    return this._vendorInvoice;
  }

  @Input()
  set vendorInvoice(vendorInvoice: VendorInvoice) {
    this._vendorInvoice = vendorInvoice;
    this.vendorInvoiceOperationComponentService.operationIsAttached(this.operation, vendorInvoice);
  }

  constructor(
    public readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    public readonly vendorInvoiceOperationComponentService: VendorInvoiceOperationComponentService,
    public readonly operationService: OperationService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async attachOperation(e: Event): Promise<void> {
    await this.vendorInvoiceOperationComponentService.attachOperation(e, this.operation, this.vendorInvoice);
    this.cdr.markForCheck();
  }

  async detachOperation(e: Event): Promise<void> {
    await this.vendorInvoiceOperationComponentService.detachOperation(e, this.operation, this.vendorInvoice);
    this.cdr.markForCheck();
  }

  detachVendorInvoice(sourceDocumentAttachment: SourceDocumentAttachment): void {
    if (sourceDocumentAttachment.sourceDocument?.externalId) {
      const operationNeedsToBeDetached: boolean =
        sourceDocumentAttachment.sourceDocument.externalId === this.vendorInvoice?.id;
      this.vendorInvoiceOperationComponentService.removeVendorInvoiceFromOperation(
        sourceDocumentAttachment.sourceDocument.externalId,
        operationNeedsToBeDetached,
      );
      this.cdr.markForCheck();
    }
  }
}
