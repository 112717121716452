import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BlankStateComponent, DougsDatePipe, LoaderComponent, ShowOperationDividerPipe, TrackByPipe } from '@dougs/ds';
import { ModalOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OperationCompactDirective } from '@dougs/operations/ui';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceOperationSearchComponentService } from '../../services/operation/vendor-invoice-operation-search.component.service';
import { VendorInvoiceOperationsComponentService } from '../../services/operation/vendor-invoice-operations.component.service';
import { VendorInvoiceOperationComponent } from './vendor-invoice-operation/vendor-invoice-operation.component';
import { VendorInvoiceOperationListSearchComponent } from './vendor-invoice-operation-list-search/vendor-invoice-operation-list-search.component';

@Component({
  selector: 'dougs-vendor-invoice-operation-list',
  standalone: true,
  imports: [
    CommonModule,
    VendorInvoiceOperationListSearchComponent,
    LoaderComponent,
    BlankStateComponent,
    ShowOperationDividerPipe,
    TrackByPipe,
    InfiniteScrollModule,
    VendorInvoiceOperationComponent,
    OperationCompactDirective,
    DougsDatePipe,
  ],
  templateUrl: './vendor-invoice-operation-list.component.html',
  styleUrls: ['./vendor-invoice-operation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: OPERATION_STATE_TOKEN, useExisting: ModalOperationsStateService }],
})
export class VendorInvoiceOperationListComponent {
  @Input() vendorInvoice!: VendorInvoice;

  constructor(
    public readonly modalOperationsStateService: ModalOperationsStateService,
    public readonly vendorInvoiceOperationsComponentService: VendorInvoiceOperationsComponentService,
    public readonly vendorInvoiceOperationSearchComponentService: VendorInvoiceOperationSearchComponentService,
  ) {}
}
