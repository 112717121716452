<ng-container *ngIf="vendorInvoiceOperationSearchComponentService.searchOperations$ | async"></ng-container>

<form [formGroup]="vendorInvoiceOperationSearchComponentService.searchFormGroup" class="operation-search-form pt-16">
  <div class="back pb-16 pl-16 pr-32">
    <dougs-button size="small" color="ghost" (click)="vendorInvoiceModalComponentService.finishCurrentStep()">
      <p class="small color-primary-700"><i class="fal fa-chevron-left mr-8"></i> Retour</p>
    </dougs-button>
  </div>
  <dougs-divider></dougs-divider>
  <dougs-search-bar
    class="pt-16 px-16"
    formControlName="search"
    [searchInProgress]="vendorInvoiceOperationSearchComponentService.hasCurrentSearch$ | async"
    [hasFilterBlock]="true"
    [inModal]="true"
    size="tiny"
    [hasSelectedFilters]="vendorInvoiceOperationSearchComponentService.hasFiltersApplied()"
    (clearSearch)="vendorInvoiceOperationSearchComponentService.onClearSearch()"
    (toggleFilterBlockEmitter)="vendorInvoiceOperationsComponentService.onFilterBlockToggled($event)"
  >
    <ng-container filterBlock>
      <div class="filter-block">
        <dougs-form-field [noMargin]="true" [autoWidth]="true">
          <label for="amount" dougsFormFieldLabel>Montant</label>
          <input formControlName="amount" type="text" id="amount" dougsFormFieldControl placeholder="Ex.: 1000" />
          <i class="fal fa-euro-sign" dougsFormFieldSuffix></i>
        </dougs-form-field>
        <dougs-form-field [noMargin]="true" [autoWidth]="true">
          <label for="date" dougsFormFieldLabel>Date</label>
          <input formControlName="date" type="text" id="date" dougsFormFieldControl placeholder="Ex.: 09/2021" />
          <i dougsFormFieldSuffix class="fal fa-calendar"></i>
        </dougs-form-field>
        <div class="pill-container">
          <dougs-pill
            type="success"
            class="mr-8 pointer"
            (click)="vendorInvoiceOperationSearchComponentService.searchBoolean('isInbound')"
            [isSelected]="vendorInvoiceOperationSearchComponentService.searchFormGroup.get('isInbound')?.value"
          >
            <i class="fal fa-arrow-up mr-4"></i>
            Entrées
          </dougs-pill>
          <dougs-pill
            type="error"
            class="pointer"
            (click)="vendorInvoiceOperationSearchComponentService.searchBoolean('isNotInbound')"
            [isSelected]="vendorInvoiceOperationSearchComponentService.searchFormGroup.get('isNotInbound')?.value"
          >
            <i class="fal fa-arrow-down mr-4"></i>
            Sorties
          </dougs-pill>
        </div>
      </div>
    </ng-container>
  </dougs-search-bar>
</form>
