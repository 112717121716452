<div class="m-24">
  <form [formGroup]="vendorInvoiceModalComponentService.formGroup">
    <div class="content__input mb-24">
      <dougs-form-field [noMargin]="true">
        <label dougsFormFieldLabel>Statut</label>
        <dougs-select class="content__input__date" formControlName="paymentStatus" dougsFormFieldControl>
          <dougs-select-option value="paid">
            <i class="fal fa-check mr-4"></i>
            Payée
          </dougs-select-option>
          <dougs-select-option value="not_paid">
            <i class="fal fa-xmark mr-4"></i>
            Non payée
          </dougs-select-option>
          <dougs-select-option value="partially_paid">
            <i class="fal fa-hourglass-clock mr-4"></i>
            Partiellement payée
          </dougs-select-option>
        </dougs-select>
      </dougs-form-field>
    </div>
    <div class="content__header">
      <h5>Détails de la facture</h5>
      <i [dougsTooltip]="invoiceDetailTooltip" class="fal fa-circle-question color-primary-700"></i>
    </div>
    <div class="content__container mt-16">
      <div class="content__invoice">
        <div class="content__input">
          <dougs-form-field [noMargin]="true">
            <label for="amount" dougsFormFieldLabel>Fournisseur</label>
            <input
              (focus)="vendorInvoiceModalComponentService.onInputFocus('supplierName')"
              (blur)="vendorInvoiceModalComponentService.onInputBlur('supplierName')"
              dougsBlurOnEnter
              id="supplier"
              formControlName="supplierName"
              type="text"
              dougsFormFieldControl
            />
          </dougs-form-field>
        </div>
        <div class="content__input">
          <dougs-input-datepicker
            (focus)="vendorInvoiceModalComponentService.onInputFocus('date')"
            (blur)="vendorInvoiceModalComponentService.onInputBlur('date')"
            class="content__input__date"
            [noMargin]="true"
            label="Date d'émission"
            formControlName="date"
          ></dougs-input-datepicker>
        </div>
        <div class="form-layout__two-columns">
          <dougs-form-field [noMargin]="true">
            <label for="amount" dougsFormFieldLabel>Montant TTC</label>
            <input
              dougsBlurOnEnter
              id="amount"
              formControlName="amount"
              type="number"
              dougsFormFieldControl
              (focus)="vendorInvoiceModalComponentService.onInputFocus('amount')"
              (blur)="vendorInvoiceModalComponentService.onInputBlur('amount')"
            />
            <dougs-currency-suffix dougsFormFieldSuffix formControlName="currency"></dougs-currency-suffix>
          </dougs-form-field>
          <dougs-form-field [noMargin]="true">
            <label for="tva" dougsFormFieldLabel>Montant TVA</label>
            <input
              dougsBlurOnEnter
              id="tva"
              formControlName="amountTva"
              type="number"
              (focus)="vendorInvoiceModalComponentService.onInputFocus('amountTva')"
              (blur)="vendorInvoiceModalComponentService.onInputBlur('amountTva')"
              dougsFormFieldControl
            />
            <dougs-currency-suffix
              dougsFormFieldSuffix
              formControlName="currency"
              [disabledDropdown]="true"
            ></dougs-currency-suffix>
          </dougs-form-field>
        </div>
        <div class="content__input content__input__start">
          <label for="amount" dougsFormFieldLabel>S’agit-il d’un avoir ?</label>
          <dougs-checkbox appearance="toggle" [showYesNo]="true" formControlName="isRefund"></dougs-checkbox>
        </div>
      </div>
    </div>
  </form>
</div>
