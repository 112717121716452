<div
  class="vendor-invoice-action-container"
  [ngClass]="{ 'compact mt-8': isCompact, 'attach-disabled': disableAttachActions }"
>
  <dougs-button
    *ngIf="!disableAttachActions"
    (click)="
      vendorInvoiceOperationComponentService.isOperationAttached$() ? detachOperation($event) : attachOperation($event)
    "
    size="small"
    [color]="vendorInvoiceOperationComponentService.isOperationAttached$() | vendorInvoiceAttachButtonColor"
  >
    {{ vendorInvoiceOperationComponentService.isOperationAttached$() ? 'Détacher' : 'Attacher' }}
  </dougs-button>
  <ng-container *ngIf="showDefaultOperationActions">
    <dougs-button
      (click)="$event.stopPropagation(); operationActionsComponentService.validateOperation(operation, true)"
      *ngIf="!isLocked"
      [round]="true"
      size="small"
      [disabled]="operation.transaction && !operation.transaction.date"
      [dougsTooltipDisable]="!operation.transaction || !!operation.transaction.date"
      dougsTooltip="'Vous ne pouvez pas valider cette opération, car la banque ne nous a pas encore transmis sa date de valeur.'"
      [noWrap]="true"
      [color]="operation.validated ? 'success' : 'primary'"
      class="operation-detail__actions__button-validate"
    >
      <div class="operation-detail__actions__button-validate__container">
        <span
          class="operation-detail__actions__button-validate__icon"
          [ngClass]="{
            'mr-4': !isEditable && !operation.validated,
            'only-validate': isEditable || operation.validated
          }"
        >
          <i class="fas" [ngClass]="{ 'fa-check': !operation.validated, 'fa-pencil': operation.validated }"></i>
        </span>
        {{ !isEditable && !operation.validated ? 'Valider' : '' }}
      </div>
    </dougs-button>
    <dougs-button
      [round]="true"
      (click)="$event.stopPropagation(); operationActionsComponentService.editOperation(operation)"
      *ngIf="isEditable"
      [noWrap]="true"
      size="small"
      color="secondary"
      class="operation-detail__actions__button-edit"
    >
      <span class="operation-detail__actions__button-edit__icon only-validate">
        <i class="far fa-pen"></i>
      </span>
    </dougs-button>
  </ng-container>
</div>
