import { Pipe } from '@angular/core';
import { Operation } from '@dougs/operations/dto';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';

@Pipe({
  name: 'vendorInvoiceOverpaid',
  standalone: true,
})
export class VendorInvoiceOverpaidPipe {
  transform(operations: Operation[], vendorInvoice: VendorInvoice): boolean {
    const totalPaid: number = operations
      ?.filter((operation) => operation.type !== 'invoiceSupplier' && operation.type !== 'invoiceCustomer')
      ?.reduce((acc, current) => acc + current.amount, 0);
    return totalPaid > 0 && vendorInvoice.amount < totalPaid;
  }
}
