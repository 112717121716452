import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, TooltipDirective } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OperationActionsComponentService } from '@dougs/operations/ui';
import { VendorInvoiceAttachButtonColorPipe } from '../../../pipes/vendor-invoice-attach-button-color.pipe';
import { VendorInvoiceOperationComponentService } from '../../../services/operation/vendor-invoice-operation.component.service';

@Component({
  selector: 'dougs-vendor-invoice-operation-actions',
  standalone: true,
  imports: [CommonModule, VendorInvoiceAttachButtonColorPipe, ButtonComponent, TooltipDirective],
  templateUrl: './vendor-invoice-operation-actions.component.html',
  styleUrls: ['./vendor-invoice-operation-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorInvoiceOperationActionsComponent {
  isLocked = false;
  isEditable = false;

  @Input() isCompact = false;
  @Input() showDefaultOperationActions = false;
  @Input() disableAttachActions = false;

  private _operation!: Operation;

  get operation(): Operation {
    return this._operation;
  }

  @Input()
  set operation(operation: Operation) {
    this._operation = operation;
    this.isEditable = this.operationService.isOperationEditable(operation);
    this.isLocked = this.operationService.isLocked(operation);
  }

  @Output() detachOperationEmitter: EventEmitter<Event> = new EventEmitter();
  @Output() attachOperationEmitter: EventEmitter<Event> = new EventEmitter();

  constructor(
    public readonly operationActionsComponentService: OperationActionsComponentService,
    public readonly vendorInvoiceOperationComponentService: VendorInvoiceOperationComponentService,
    public readonly operationService: OperationService,
  ) {}

  detachOperation(e: MouseEvent): void {
    this.detachOperationEmitter.emit(e);
  }

  attachOperation(e: MouseEvent): void {
    this.attachOperationEmitter.emit(e);
  }
}
