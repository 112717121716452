<div
  dougsOperationCompact
  #compactDirective="compactDirective"
  class="mt-16"
  [ngStyle]="{ position: 'relative' }"
  *ngIf="vendorInvoiceOperationsStateService.operations$ | async as operations"
>
  <div
    @horizontalSlideIn
    @horizontalSlideOut
    [@.disabled]="vendorInvoiceModalComponentService.isLoadingOperations$ | async"
    *ngIf="
      !(vendorInvoiceModalComponentService.isLoadingOperations$ | async) &&
      vendorInvoice.prefillStatus !== 'initialised' &&
      (vendorInvoiceModalComponentService.matchedOperation$ | async)
    "
    class="mx-24 suggest-box"
  >
    <div class="px-16 py-8 suggest-box__header">
      <p class="suggest-box__label tiny color-primary-700 bold mb-8">
        <i class="fal fa-sparkles color-yellow-500 mr-4"></i>
        Cette opération semble correspondre à votre facture
      </p>
      <div class="suggest-box__buttons">
        <dougs-button
          size="small"
          [fullWidth]="true"
          (click)="vendorInvoiceModalComponentService.addMatchedOperation()"
        >
          Oui, attacher
        </dougs-button>
        <dougs-button
          color="secondary"
          size="small"
          [fullWidth]="true"
          (click)="vendorInvoiceModalComponentService.rejectMatchedOperation()"
        >
          Non, fermer
        </dougs-button>
      </div>
    </div>
    <dougs-vendor-invoice-operation
      @verticalSlideOut
      [isInForm]="true"
      [operation]="vendorInvoiceModalComponentService.matchedOperation$ | async"
      [vendorInvoice]="vendorInvoice"
      [showDefaultOperationActions]="true"
      [isCompact]="compactDirective.isCompact"
      [disableAttachActions]="true"
    ></dougs-vendor-invoice-operation>
  </div>
  <div @horizontalOperationsSlideIn @horizontalSlideOut *ngIf="operations.length">
    <div *ngIf="operations | vendorInvoiceOverpaid: vendorInvoice" class="alert-container px-24 pb-16 hint">
      <i class="fal fa-info-circle mr-8"></i>
      <p class="tiny">
        Nous avons remarqué que le montant de l'opération est plus élevé que celui de la facture. Êtes-vous sur qu’il
        s’agit de la bonne opération ?
      </p>
    </div>
    <div class="content__operations-attached mx-24">
      <ng-container
        *ngFor="
          let operation of operations;
          trackBy: 'id' | trackBy;
          let isFirst = first;
          let isLast = last;
          let index = index
        "
      >
        <div
          *ngIf="operations | showOperationDivider: index : isFirst"
          @verticalSlideOut
          [ngStyle]="{ top: 0 + 'px' }"
          class="px-16 py-8 operation-list__date"
        >
          {{ operation.date | date: 'MMMM yyyy' | titlecase }}
        </div>
        <dougs-vendor-invoice-operation
          @verticalSlideOut
          [isLast]="isLast"
          [isInForm]="true"
          [operation]="operation"
          [vendorInvoice]="vendorInvoice"
          [showDefaultOperationActions]="true"
          [isCompact]="compactDirective.isCompact"
        ></dougs-vendor-invoice-operation>
      </ng-container>
    </div>
  </div>
</div>
