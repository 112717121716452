import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent, DougsDatePipe, ShowOperationDividerPipe, TrackByPipe } from '@dougs/ds';
import { VendorInvoiceOperationsStateService } from '@dougs/operations/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OperationCompactDirective, OperationComponent } from '@dougs/operations/ui';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceOverpaidPipe } from '../../../pipes/vendor-invoice-overpaid.pipe';
import { VendorInvoiceModalComponentService } from '../../../services/modals/vendor-invoice-modal.component.service';
import { VendorInvoiceOperationComponent } from '../../vendor-invoice-operation-list/vendor-invoice-operation/vendor-invoice-operation.component';

const ANIMATION_TIMING = '500ms cubic-bezier(.8,.13,0,1.04)';

@Component({
  selector: 'dougs-vendor-invoice-details-operations',
  standalone: true,
  imports: [
    CommonModule,
    OperationCompactDirective,
    TrackByPipe,
    ShowOperationDividerPipe,
    VendorInvoiceOperationComponent,
    ButtonComponent,
    OperationComponent,
    DougsDatePipe,
    VendorInvoiceOverpaidPipe,
  ],
  templateUrl: './vendor-invoice-details-operations.component.html',
  styleUrls: ['./vendor-invoice-details-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('verticalSlideOut', [
      transition(':leave', [
        style({ height: '*', paddingTop: '*', paddingBottom: '*', opacity: 0.7 }),
        animate(
          '300ms cubic-bezier(.8,.13,0,1.04)',
          style({
            opacity: 0,
            paddingTop: 0,
            paddingBottom: 0,
            height: 0,
          }),
        ),
      ]),
    ]),
    trigger('horizontalOperationsSlideIn', [
      transition(':enter', [
        style({
          transform: 'translateX(50%)',
          opacity: 0,
        }),
        animate(
          '400ms',
          style({
            transform: 'translateX(50%)',
            opacity: 0,
          }),
        ),
        animate(
          ANIMATION_TIMING,
          style({
            opacity: 1,
            transform: 'translateX(0)',
          }),
        ),
      ]),
    ]),
    trigger('horizontalSlideIn', [
      transition(':enter', [
        style({
          transform: 'translateX(50%)',
          opacity: 0,
        }),
        animate(
          ANIMATION_TIMING,
          style({
            opacity: 1,
            transform: 'translateX(0)',
          }),
        ),
      ]),
    ]),
    trigger('horizontalSlideOut', [
      transition(':leave', [
        style({
          paddingTop: 0,
          marginTop: 0,
          marginRight: '*',
          marginLeft: '*',
          position: 'absolute',
          top: '0',
          transform: 'translateX(0)',
          width: '*',
          overflow: 'hidden',
          opacity: 1,
          'z-index': 4,
        }),
        animate(
          ANIMATION_TIMING,
          style({
            opacity: 0,
            transform: 'translateX(-50%)',
          }),
        ),
      ]),
    ]),
  ],
})
export class VendorInvoiceDetailsOperationsComponent {
  private _vendorInvoice!: VendorInvoice;

  @Input() set vendorInvoice(vendorInvoice: VendorInvoice) {
    this._vendorInvoice = vendorInvoice;
  }

  get vendorInvoice(): VendorInvoice {
    return this._vendorInvoice;
  }

  constructor(
    public readonly vendorInvoiceOperationsStateService: VendorInvoiceOperationsStateService,
    public readonly vendorInvoiceModalComponentService: VendorInvoiceModalComponentService,
    public readonly synchronizedAccountStateService: SynchronizedAccountStateService,
  ) {}
}
