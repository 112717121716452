<div
  infiniteScroll
  (scrolled)="vendorInvoiceOperationsComponentService.onScroll()"
  [immediateCheck]="true"
  [scrollWindow]="false"
  class="attach-operations"
>
  <dougs-vendor-invoice-operation-list-search></dougs-vendor-invoice-operation-list-search>
  <dougs-vendor-invoice-operation-list
    [vendorInvoice]="vendorInvoiceModalComponentService.vendorInvoiceUpdated$ | async"
  ></dougs-vendor-invoice-operation-list>
</div>
