import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import {
  ButtonComponent,
  DrawerCloseDirective,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  IconButtonComponent,
  MemoComponent,
  ModalCloseDirective,
} from '@dougs/ds';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceActionsComponentService } from '../../../services/details/vendor-invoice-actions.component.service';
import { VendorInvoiceModalComponentService } from '../../../services/modals/vendor-invoice-modal.component.service';

@Component({
  selector: 'dougs-vendor-invoice-details-header',
  standalone: true,
  imports: [
    CommonModule,
    MemoComponent,
    ButtonComponent,
    DropdownComponent,
    DropdownOptionComponent,
    DropdownTriggerDirective,
    ReactiveFormsModule,
    ModalCloseDirective,
    DrawerCloseDirective,
    IconButtonComponent,
  ],
  templateUrl: './vendor-invoice-details-header.component.html',
  styleUrls: ['./vendor-invoice-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorInvoiceDetailsHeaderComponent {
  @Input() vendorInvoice!: VendorInvoice;
  @Input() url!: SafeUrl;

  constructor(
    public readonly vendorInvoiceModalComponentService: VendorInvoiceModalComponentService,
    public readonly vendorInvoiceActionsComponentService: VendorInvoiceActionsComponentService,
  ) {}
}
