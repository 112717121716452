import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilKeyChanged,
  filter,
  lastValueFrom,
  map,
  Observable,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { CurrencyISOCode } from '@dougs/core/config-back';
import { InvoiceFile } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import { CompanyChangedStateService } from '@dougs/core/socket';
import { RecursivePartial, toPromise } from '@dougs/core/utils';
import { DrawerRef } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { SocketOperationsStateService, VendorInvoiceOperationsStateService } from '@dougs/operations/shared';
import { ServicesState } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { VendorInvoice, VendorInvoicePaymentStatus, VendorInvoicePrefillStatus } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { VendorInvoiceActionsComponentService } from '../details/vendor-invoice-actions.component.service';
import { VendorInvoiceQueueService } from '../details/vendor-invoice-queue.service';
import { VendorInvoiceOperationSearchComponentService } from '../operation/vendor-invoice-operation-search.component.service';

@Injectable()
export class VendorInvoiceModalComponentService {
  private readonly isLoadingOperations: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoadingOperations$: Observable<boolean> = this.isLoadingOperations.asObservable();

  constructor(
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly drawerRef: DrawerRef,
    private readonly metricsService: MetricsService,
    private readonly vendorInvoiceQueueService: VendorInvoiceQueueService,
    private readonly vendorInvoiceOperationsStateService: VendorInvoiceOperationsStateService,
    private readonly vendorInvoiceOperationSearchComponentService: VendorInvoiceOperationSearchComponentService,
    private readonly vendorInvoiceActionsComponentService: VendorInvoiceActionsComponentService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly companyDemoStateService: CompanyDemoStateService,
    private readonly companyChangedStateService: CompanyChangedStateService,
    private readonly socketOperationsStateService: SocketOperationsStateService,
    private readonly location: Location,
  ) {}

  readonly canAddVendorInvoiceOperation$: Observable<boolean> = this.companyStateService.activeCompany$.pipe(
    withLatestFrom(this.userStateService.loggedInUser$),
    map(
      ([company, loggedInUser]: [Company, User]) =>
        company?.flags.includes('allowVendorCategoryAssociation') && loggedInUser?.isAccountantOrAdmin,
    ),
  );

  shouldShowOperations$: Observable<boolean> = combineLatest([
    this.companyServicesStateService.services$,
    this.synchronizedAccountStateService.synchronizedAccounts$,
    this.companyDemoStateService.hasDemoMode$,
  ]).pipe(
    map(
      ([services, synchronizedAccounts, hasDemoMode]) =>
        services.accounting.shouldShowModuleLink && !!synchronizedAccounts?.length && !hasDemoMode,
    ),
  );

  focusableFormControlNameList: string[] = ['memo', 'amount', 'date', 'amountTva', 'supplierName'];
  formFocusedMap: Record<string, boolean> = {};

  isAttachingOperations = false;

  private readonly vendorInvoiceSubject: BehaviorSubject<VendorInvoice | null> =
    new BehaviorSubject<VendorInvoice | null>(null);
  vendorInvoice$: Observable<VendorInvoice | null> = this.vendorInvoiceSubject.asObservable();

  vendorInvoiceFile$: Observable<InvoiceFile | null> = this.vendorInvoice$.pipe(
    filter((vendorInvoice): vendorInvoice is VendorInvoice => !!vendorInvoice),
    distinctUntilKeyChanged('id'),
    map((vendorInvoice) =>
      vendorInvoice?.fileName && vendorInvoice?.fileType && vendorInvoice?.filePath
        ? {
            fileName: vendorInvoice.fileName,
            fileType: vendorInvoice.fileType,
            filePath: vendorInvoice.filePath,
          }
        : null,
    ),
  );

  matchedOperation$: Observable<Operation | null> = combineLatest([
    this.vendorInvoiceOperationsStateService.matchedOperation$,
    this.vendorInvoice$,
  ]).pipe(
    map(([matchedOperation, vendorInvoice]) =>
      vendorInvoice?.operationAttachments?.some(
        (operationAttachment) => operationAttachment.operation?.id === matchedOperation?.id,
      )
        ? null
        : (matchedOperation as Operation),
    ),
  );

  isAccrualInvoice$: Observable<boolean> = this.vendorInvoice$.pipe(
    map((vendorInvoice) => !!vendorInvoice?.accrualOperationAttachment?.operation?.id),
  );

  updateOperationFromSocket$: Observable<void> = this.companyChangedStateService.companyChanged$.pipe(
    concatMap((companyChanged) => this.socketOperationsStateService.updateOperationFromSocket(companyChanged)),
  );

  get vendorInvoice(): VendorInvoice {
    return this.vendorInvoiceQueueService.model;
  }

  get amountFormControl(): FormControl {
    return this.formGroup.get('amount') as FormControl;
  }

  get dateFormControl(): FormControl {
    return this.formGroup.get('date') as FormControl;
  }

  get supplierFormControl(): FormControl {
    return this.formGroup.get('supplierName') as FormControl;
  }

  get amountTvaFormControl(): FormControl {
    return this.formGroup.get('amountTva') as FormControl;
  }

  get currencyFormControlValue(): CurrencyISOCode {
    return (this.formGroup.get('currency')?.value as CurrencyISOCode) ?? '';
  }

  get formGroupValue(): RecursivePartial<VendorInvoice> {
    return {
      ...this.formGroup.value,
      amount: this.amountFormControl?.value ? Math.round(this.amountFormControl.value * 100) / 100 : 0,
      amountTva: this.amountTvaFormControl?.value ? Math.round(this.amountTvaFormControl.value * 100) / 100 : 0,
    };
  }

  vendorInvoiceUpdated$: Observable<VendorInvoice | undefined> = this.vendorInvoiceStateService.vendorInvoices$.pipe(
    map((vendorInvoices) => vendorInvoices.find((vendorInvoice) => vendorInvoice.id === this.vendorInvoice.id)),
    tap((vendorInvoice) => {
      if (vendorInvoice) {
        this.updateVendorInvoice(vendorInvoice);
      }
    }),
  );

  populateFormGroup$: Observable<void> = this.vendorInvoice$.pipe(
    map((vendorInvoice) => {
      if (vendorInvoice) {
        this.populateFormGroup(vendorInvoice);
        this.formatFormGroup(vendorInvoice);
      }
    }),
  );

  currencyClassSuffix$: Observable<string | null> = this.vendorInvoice$.pipe(
    map((vendorInvoice: VendorInvoice | null) =>
      vendorInvoice ? this.getVendorInvoiceClassSuffix(this.currencyFormControlValue) : null,
    ),
  );

  formGroup = new FormGroup(
    {
      memo: new FormControl('', { nonNullable: true, updateOn: 'change' }),
      paymentStatus: new FormControl<VendorInvoicePaymentStatus>(VendorInvoicePaymentStatus.PAID, {
        nonNullable: true,
      }),
      amount: new FormControl(0, { nonNullable: true }),
      label: new FormControl('', { nonNullable: true }),
      isRefund: new FormControl(false, { nonNullable: true, updateOn: 'change' }),
      date: new FormControl<Date | string>('', { nonNullable: true }),
      amountTva: new FormControl(0, { nonNullable: true }),
      currency: new FormControl<CurrencyISOCode>(CurrencyISOCode.EUR, { nonNullable: true }),
      supplierName: new FormControl('', { nonNullable: true }),
    },
    { updateOn: 'blur' },
  );

  formGroupValueChanges$: Observable<void> = this.formGroup.valueChanges.pipe(
    map(() => {
      const { amount, isRefund }: { amount: number; isRefund: boolean } = this.handleAmountAndRefundValues();
      this.vendorInvoiceQueueService.updateModel(
        {
          ...this.vendorInvoice,
          ...this.formGroupValue,
          isRefund,
          amount,
        },
        null,
      );
    }),
  );

  readonly addOperationButtonDisabled$: Observable<boolean> = this.vendorInvoice$.pipe(
    map(
      (vendorInvoice: VendorInvoice | null) =>
        !vendorInvoice?.amount ||
        !vendorInvoice?.date ||
        vendorInvoice.isRefund ||
        vendorInvoice.currency !== CurrencyISOCode.EUR,
    ),
  );

  onInputFocus(formControlName: string): void {
    if (!this.formFocusedMap[formControlName]) {
      this.formFocusedMap[formControlName] = true;
    }
  }

  onInputBlur(formControlName: string): void {
    delete this.formFocusedMap[formControlName];
  }

  async refreshVendorInvoiceOperations(vendorInvoice: VendorInvoice): Promise<void> {
    const services: ServicesState = await toPromise(this.companyServicesStateService.services$);
    if (services.accounting.shouldShowModuleLink) {
      this.isLoadingOperations.next(true);
      await this.vendorInvoiceOperationsStateService.refreshOperations(vendorInvoice);
      if (vendorInvoice?.matchedOperation?.id) {
        await this.vendorInvoiceOperationsStateService.refreshMatchedOperation(
          vendorInvoice.companyId,
          vendorInvoice.matchedOperation?.id,
        );
      }
      if (vendorInvoice?.accrualOperationAttachment?.operation?.id) {
        await this.vendorInvoiceOperationsStateService.refreshAccrualOperation(
          vendorInvoice.companyId,
          vendorInvoice.accrualOperationAttachment.operation.id,
        );
      }
      this.isLoadingOperations.next(false);
    }
  }

  async deleteVendorInvoice(vendorInvoice: VendorInvoice): Promise<void> {
    const hasBeenDeleted: boolean = await this.vendorInvoiceActionsComponentService.deleteVendorInvoice(vendorInvoice);
    if (hasBeenDeleted) {
      this.drawerRef.close();
    }
  }

  populateFormGroup(vendorInvoice: VendorInvoice): void {
    for (const formControlName of this.focusableFormControlNameList) {
      if (!this.formFocusedMap[formControlName]) {
        const initialFormControlValue: any = vendorInvoice[formControlName as keyof VendorInvoice];
        const formControlValue: any =
          formControlName === 'amount' && vendorInvoice.isRefund
            ? -(initialFormControlValue as number)
            : initialFormControlValue;
        this.formGroup.patchValue(
          {
            [formControlName]: formControlValue,
          },
          { emitEvent: false },
        );
      }
    }
    this.formGroup.patchValue(
      {
        paymentStatus: vendorInvoice.paymentStatus,
        label: vendorInvoice.label,
        isRefund: vendorInvoice.isRefund,
        currency: vendorInvoice.currency,
      },
      { emitEvent: false },
    );
  }

  formatFormGroup(vendorInvoice: VendorInvoice): void {
    if (vendorInvoice.isLocked) {
      this.formGroup.disable({ emitEvent: false });
      this.formGroup.controls.paymentStatus.enable({ emitEvent: false });
      this.formGroup.controls.memo.enable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }

  async updatePrefillStatusOnAnimationEnded(): Promise<void> {
    if (this.vendorInvoice.prefillStatus === VendorInvoicePrefillStatus.INITIALISED) {
      await this.vendorInvoiceStateService.stopVendorInvoicePrefill(
        this.vendorInvoice.companyId,
        this.vendorInvoice.id,
      );
    }
  }

  updateVendorInvoice(vendorInvoice: VendorInvoice): void {
    this.vendorInvoiceQueueService.setCurrentModel(vendorInvoice);
    this.vendorInvoiceSubject.next(vendorInvoice);
  }

  finishCurrentStep(): void {
    if (this.isAttachingOperations) {
      this.isAttachingOperations = false;
      this.vendorInvoiceOperationSearchComponentService.onClearSearch();
    } else {
      this.drawerRef.close();
    }
  }

  private getVendorInvoiceClassSuffix(currencyCode: CurrencyISOCode): string | null {
    switch (true) {
      case currencyCode === CurrencyISOCode.EUR:
      case currencyCode === null:
        return 'fa-euro-sign';
      case currencyCode === CurrencyISOCode.USD:
      case currencyCode === CurrencyISOCode.HKD:
      case currencyCode === CurrencyISOCode.CAD:
      case currencyCode === CurrencyISOCode.AUD:
        return 'fa-dollar-sign';
      case currencyCode === CurrencyISOCode.GBP:
        return 'fa-pound-sign';
      case currencyCode === CurrencyISOCode.JPY:
      case currencyCode === CurrencyISOCode.CNY:
        return 'fa-yen-sign';
      default:
        return null;
    }
  }

  async addMatchedOperation(): Promise<void> {
    const matchedOperation: Operation | undefined = await toPromise(
      this.vendorInvoiceOperationsStateService.matchedOperation$,
    );
    if (matchedOperation && this.vendorInvoice?.matchedOperation) {
      this.metricsService.pushMixpanelEvent('Vendor Invoice Operation Match Accepted', {
        Module: new RegExp(/.*\/([^?]+)/).exec(this.location.path())?.[1],
      });
      await this.vendorInvoiceStateService.acceptMatchedOperation(this.vendorInvoice.companyId, this.vendorInvoice.id);
      this.vendorInvoiceOperationsStateService.resetMatchedOperation();
      const refreshedMatchedOperation: Operation = await this.vendorInvoiceOperationsStateService.refreshOperationById(
        matchedOperation.companyId,
        matchedOperation.id,
      );
      this.vendorInvoiceOperationsStateService.addOperationState(refreshedMatchedOperation);
    }
  }

  async rejectMatchedOperation(): Promise<void> {
    const matchedOperation: Operation | undefined = await lastValueFrom(
      this.vendorInvoiceOperationsStateService.matchedOperation$.pipe(take(1)),
    );
    if (matchedOperation && this.vendorInvoice?.matchedOperation) {
      this.metricsService.pushMixpanelEvent('Vendor Invoice Operation Match Rejected', {
        Module: new RegExp(/.*\/([^?]+)/).exec(this.location.path())?.[1],
      });
      await this.vendorInvoiceStateService.rejectMatchedOperation(this.vendorInvoice.companyId, this.vendorInvoice.id);
      this.vendorInvoiceOperationsStateService.resetMatchedOperation();
    }
  }

  async createVendorInvoiceOperation(operationType?: string): Promise<void> {
    const operationCreated: Operation | null = await this.vendorInvoiceOperationsStateService.createOperation(
      this.vendorInvoice.companyId,
      {
        type: operationType ?? 'vendorInvoice',
        memo: this.supplierFormControl?.value ?? '',
        date: this.dateFormControl?.value ?? new Date(),
        amount: this.amountFormControl.value,
        breakdowns: [
          {
            amount: this.amountFormControl.value,
            categoryId: -1,
          },
          {
            amount: 0,
            categoryId: -1,
            isCounterpart: true,
          },
        ],
      },
    );
    if (operationCreated) {
      await this.vendorInvoiceStateService.attachOperation(operationCreated, this.vendorInvoice);
      await this.vendorInvoiceOperationsStateService.refreshOperationById(
        this.vendorInvoice.companyId,
        operationCreated.id,
      );
    }
  }

  private handleAmountAndRefundValues(): { amount: number; isRefund: boolean } {
    let isRefund: boolean = this.formGroupValue?.isRefund || false;
    let amount: number = this.formGroupValue?.amount || 0;
    const currentVendorInvoiceAmount: number = this.vendorInvoice.isRefund
      ? -this.vendorInvoice.amount
      : this.vendorInvoice.amount;
    if (!!this.formGroupValue?.amount && this.formGroupValue.amount !== currentVendorInvoiceAmount) {
      // Amount changed -> refund update
      isRefund = this.formGroupValue?.amount < 0;
    } else if (this.formGroupValue.isRefund !== this.vendorInvoice.isRefund) {
      // Refund changed -> amount update
      amount = -amount;
    }
    return { amount, isRefund };
  }
}
