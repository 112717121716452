import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vendorInvoiceAttachButtonColor',
  standalone: true,
})
export class VendorInvoiceAttachButtonColorPipe implements PipeTransform {
  transform(isOperationAttached: boolean): 'primary' | 'secondary' {
    return isOperationAttached ? 'secondary' : 'primary';
  }
}
